import { API } from '@/inc/api';
import { _st } from '@/softech';

export const users = {
    namespaced: true,
    state: {
        users       : [],
        needRefresh : true,
    },
    mutations: {
        setUsers( state, payload ) {
            state.users = payload;
        },
        setNeedRefresh( state, payload ) {
            state.needRefresh = payload;
        }
    },
    actions: {
        async loadUsers({ commit, state }) {
            if( !state.needRefresh ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let res = await api.get('/admin/users');

                if( !res.data.status ) {
                    return Promise.reject( res.data );
                }

                commit('setUsers', res.data.data);
                commit('setNeedRefresh', false);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async saveUser({ commit }, user) {
            try {
                let api = new API();
                let res = null;
                
                if( _st.isNUE( user.id ) )
                    res = await api.post('/admin/user', user);
                else
                    res = await api.put('/admin/user', user);

                if( !res.data.status ) {
                    return Promise.resolve( res.data );
                }

                commit('setNeedRefresh', true);
                return Promise.resolve( res.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        }
    },
    getters: {
        users           : (state) => state.users,
        activeUsers     : (state) => state.users.filter(u => u.active === true).sort((a,b) => {
            if( a.fullName < b.fullName ) return -1;
            else if( a.fullName > b.fullName ) return 1;
            else return 0;
        }),
        needRefresh     : (state) => state.needRefresh,
    }
}
