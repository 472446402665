import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import { _st } from '@/softech';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: { requiresAuth: false },
    component: () => import('@/views/Login.vue')
  },{
    path: '/dashboard',
    name: 'Dashboard',
    meta: { requiresAuth: true, privileges: ['adminAccess'] },
    component: () => import('@/views/Dashboard.vue')
  },{
    path: '/stores',
    name: 'Stores',
    meta: { requiresAuth: true, privileges: ['adminAccess','storeManagement'] },
    component: () => import('@/views/StoreList.vue')
  },{
    path: '/stores/:storeId',
    name: 'Store',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess','storeManagement'] },
    component: () => import('@/views/Store.vue')
  },{
    path: '/stores/new',
    name: 'New store',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess','storeManagement'] },
    component: () => import('@/views/Store.vue')
  },{
    path: '/users',
    name: 'Users',
    meta: { requiresAuth: true, privileges: ['adminAccess','userManagement'] },
    component: () => import('@/views/UserList.vue')
  },{
    path: '/users/:userId',
    name: 'User',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess','userManagement'] },
    component: () => import('@/views/User.vue')
  },{
    path: '/users/new',
    name: 'New user',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess','userManagement'] },
    component: () => import('@/views/User.vue')
  },{
    path: '/products',
    name: 'Products',
    meta: { requiresAuth: true, privileges: ['adminAccess','productManagement'] },
    component: () => import('@/views/ProductList.vue')
  },{
    path: '/products/:productId',
    name: 'Product',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess','productManagement'] },
    component: () => import('@/views/Product.vue')
  },{
    path: '/products/new',
    name: 'New product',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess','productManagement'] },
    component: () => import('@/views/Product.vue')
  },{
    path: '/promo',
    name: 'Promo codes',
    meta: { requiresAuth: true, privileges: ['adminAccess','promoManagement'] },
    component: () => import('@/views/PromoCodeList.vue')
  },{
    path: '/promo/:code',
    name: 'Promo code',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess','promoManagement'] },
    component: () => import('@/views/PromoCode.vue')
  },{
    path: '/promo/new',
    name: 'New promo code',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess','promoManagement'] },
    component: () => import('@/views/PromoCode.vue')
  },{
    path: '/reports',
    name: 'Reports',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/views/ReportList.vue')
  },{
    path: '/reports/rentnofinalpayment',
    name: 'No final payment',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptRentNoFinalPayment.vue')
  },{
    path: '/reports/rentcommission',
    name: 'Rent commissions',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptRentCommission.vue')
  },{
    path: '/reports/dtmcommission',
    name: 'DTM commissions',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptDtmCommission.vue')
  },{
    path: '/reports/promoaudit',
    name: 'Promo codes audit',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptPromoCodes.vue')
  },{
    path: '/reports/rentlatereturns',
    name: 'Late returns',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptLateReturns.vue')
  },{
    path: '/reports/rentunits',
    name: 'Unit report',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptRentUnit.vue')
  },{
    path: '/reports/rentaverageparty',
    name: 'Average wedding party',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptAvgWeddingParty.vue')
  },{
    path: '/reports/rentdeliveredorders',
    name: 'Delivered orders',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptRentDeliveredOrders.vue')
  },{
    path: '/reports/rentincome',
    name: 'Rent income',
    meta: { requiresAuth: true, privileges: ['reportAccess'] },
    component: () => import('@/reports/RptRentIncome.vue')
  },{
    path: '/reports/rentrevenue',
    name: 'Rent revenue',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptRentRevenue.vue')
  },{
    path: '/reports/rentreplacements',
    name: 'Rent replacements',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptRentReplacements.vue')
  },{
    path: '/reports/open-to-buy',
    name: 'Rent replacements',
    meta: { requiresAuth: true, privileges: ['adminAccess','openToBuy'] },
    component: () => import('@/reports/RptOpenToBuy.vue')
  },{
    path: '/reports/merchandise-receiving',
    name: 'Rent replacements',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptMerchandiseReceiving.vue')
  },{
    path: '/reports/studentcontracts',
    name: 'Student contracts',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptStudenContracts.vue')
  },{
    path: '/reports/kpis',
    name: 'Sales KPIs',
    meta: { requiresAuth: true, privileges: ['adminAccess','reportAccess'] },
    component: () => import('@/reports/RptKpis.vue')
  },{
    path: '/customers',
    name: 'Customers',
    meta: { requiresAuth: true, privileges: ['adminAccess'] },
    component: () => import('@/views/CustomerList.vue')
  },{
    path: '/customers/:customerId',
    name: 'Customer',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess'] },
    component: () => import('@/views/Customer.vue')
  },{
    path: '/customers/new',
    name: 'New customer',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess'] },
    component: () => import('@/views/Customer.vue')
  },{
    path: '/reservations',
    name: 'Reservations',
    meta: { requiresAuth: true, privileges: ['adminAccess'] },
    component: () => import('@/views/ReservationList.vue')
  },{
    path: '/reservations/:reservationId',
    name: 'Reservation',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess'] },
    component: () => import('@/views/Reservation.vue')
  },{
    path: '/orders',
    name: 'Orders',
    meta: { requiresAuth: true, privileges: ['adminAccess'] },
    component: () => import('@/views/OrderList.vue')
  },{
    path: '/orders/:orderId',
    name: 'Order',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess'] },
    component: () => import('@/views/Order.vue')
  },{
    path: '/events',
    name: 'Events',
    meta: { requiresAuth: true, privileges: ['adminAccess'] },
    component: () => import('@/views/EventList.vue')
  },{
    path: '/events/:eventId',
    name: 'Event',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess'] },
    component: () => import('@/views/Event.vue')
  },{
    path: '/leads',
    name: 'Leads',
    meta: { requiresAuth: true, privileges: ['adminAccess'] },
    component: () => import('@/views/LeadList.vue')
  },{
    path: '/leads/:leadId',
    name: 'Leads',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess'] },
    component: () => import('@/views/Lead.vue')
  },{
    path: '/open-to-buy',
    name: 'Open to buy',
    props: true,
    meta: { requiresAuth: true, privileges: ['adminAccess','openToBuy'] },
    component: () => import('@/views/OpenToBuy.vue')
  },{
    path: '/404',
    name: 'Not found',
    meta: { requiresAuth: true, privileges: [] },
    component: () => import('@/views/NotFound.vue')
  },{
    path: '/403',
    name: 'Forbidden',
    meta: { requiresAuth: true, privileges: [] },
    component: () => import('@/views/Forbidden.vue')
  },
  
  // default route
  {
    path: '*',
    redirect: '/404'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/authAction');

	const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const privileges = to.matched[0].meta?.privileges??[];
	const isLoggedIn = store.getters['auth/isLoggedIn'];

	if(requiresAuth && !isLoggedIn) {
		next('/');
	} 
  else {

    const user = store.getters['auth/user'];
    if( privileges !== undefined && privileges !== null && privileges.length > 0 ) {
      let hasAccess = true;
      for( let p of privileges ) {
        if( _st.isNU( user.privileges[p] ) 
            && _st.isNU( user.privileges['superAdmin'] ) ) {
          next('/403');
          hasAccess = false;
          break;
        }
      }

      if( hasAccess )
        next();
    }
    else {
      next();
    }

	}
})

router.afterEach((to) => {
	Vue.nextTick(() => { document.title = to.name ? `LFA Admin Panel | ${to.name}` : 'LFA Admin Panel' });
});

export default router
