import router from '@/router';
import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const auth = {
    namespaced: true,
    state: {
        userId      : null,
        user        : null,
        token       : null
    },
    mutations: {
        setUserId( state, payload ) {
            state.userId = payload;
        },
        setUser( state, payload ) {
            state.user = payload;
        },
        setToken( state, payload ) {
            state.token = payload;
        }
    },
    actions: {
        logout({ commit }) {
            try {
                commit('setUserId', null);
                commit('setUser', null);
                commit('setToken', null);

                localStorage.removeItem('lfa-auth');

                router.push('/').catch((err) => {
                    if( err.name != 'NavigationDuplicated') throw err;
                });
                return Promise.resolve();
            }
            catch(error) {
                console.log(error);
                return Promise.reject();
            }
        },
        async login(_, { username, password }) {
            try {
                let api = new API();
                let res = await api.post('/admin/signin', { username, password });

                if( res.data.status === true ) {
                    if( _st.isNUE( res.data.nextStep ) ) {
                        localStorage.setItem('lfa-auth', res.data.token);
                        router.push('/dashboard');
                        bus.$emit('login');
                    }
                }

                return Promise.resolve( res.data );
            }
            catch( error ) {
                console.log( error );
                return Promise.reject( error );
            }
        },
        async changePassword(_, { oldPassword, newPassword }) {
            try {
                let api = new API();
                let res = await api.post('/admin/change-password', { 
                    current : oldPassword, 
                    password: newPassword 
                });
                
                return Promise.resolve( res );
            } 
            catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async changePasswordLogin(_, { username, password, token }) {
            try {
                let api = new API();
                let res = await api.post('/admin/change-pwd-login', { username, password, token });

                if( res.data.status === true ) {
                    localStorage.setItem('lfa-auth', res.data.token);
                    router.push('/dashboard');
                    bus.$emit('login');
                }

                return Promise.resolve( res.data );
            }
            catch( error ) {
                console.log( error );
                return Promise.reject( error );
            }
        },
        async forgotPassword(_, email) {
            try {
                console.log(email);
                // return await Auth.forgotPassword(email);
            } 
            catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async submitForgotPassword(_, { email, code, newPassword }) {
            try {
                console.log( email, code, newPassword );
                // return await Auth.forgotPasswordSubmit(email, code, newPassword);

                return Promise.resolve();
            } 
            catch(error) {
                console.log(error);
                return Promise.reject(error);
            }
        },
        async authAction({ commit, state }) {
            let token = localStorage.getItem('lfa-auth');
            if( _st.isNUE( token ) ) {
                return Promise.resolve();
            }

            var jwt = require('jsonwebtoken');
            let decoded = jwt.decode( token, {complete: true} );

            commit('setUserId', decoded.payload.usr);
            commit('setToken', token);

            if( _st.isNUE( state.user ) || state.user.id != state.userId ) {
                let api = new API();
                let res = await api.get( `/admin/user/${decoded.payload.usr}` );

                if( res.data.status === true ) {
                    commit( 'setUser', res.data.data );
                }
            }
        }
    },
    getters: {
        user                : (state) => state.user,
		isLoggedIn          : (state) => !_st.isNUE(state.token),
        token               : (state) => state.token
    }
}