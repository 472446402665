import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './modules/auth';
import { stores } from './modules/stores';
import { products } from './modules/products';
import { users } from './modules/users';
import { promo } from './modules/promo';
import { customers } from './modules/customers';
import { reservations } from './modules/reservations';
import { orders } from './modules/orders';
import { events } from './modules/events';
import { leads } from './modules/leads';
import { pickupLocations } from './modules/pickupLocations';
import { extraItems } from './modules/extraItems';
import { items } from './modules/items'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    stores,
    products,
    users,
    promo,
    customers,
    reservations,
    orders,
    events,
    leads,
    pickupLocations,
    extraItems,
    items
  }
})
