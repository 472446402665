<template>
    <div>
        <v-row class="mt-3">
            <v-col xs="6" md="4">
                <v-select :items="customerTypes" v-model="local.customerTypeId" item-value="id" item-text="name" label="Type" dense
                    :error-messages="customerTypeError" @change="update('customerTypeId', local.customerTypeId)"></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                <v-text-field v-model="local.firstName" label="First Name" :error-messages="firstNameError" required dense
                    @input="update('firstName', local.firstName)" maxlength="50"></v-text-field>
            </v-col>
            <v-col xs="12" md="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pl-2'">
                <v-text-field v-model="local.lastName" label="Last Name" :error-messages="lastNameError" required dense
                    @input="update('lastName', local.lastName)" maxlength="50"></v-text-field>
            </v-col>
        </v-row>

        <v-row no-gutters class="mt-3">
            <v-col xs="12" lg="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pr-2'">
                <div>
                    <div class="subheadi2 grey--text">
                        General Information
                    </div>
                </div>
                <div>
                    <v-text-field v-model="local.phone" label="Phone" v-mask="'(###) ###-####'" :error-messages="phoneError" required
                        @input="update('phone', local.phone)"></v-text-field>
                </div>
                <div>
                    <v-text-field v-model="local.email" label="Email" :error-messages="emailError" required
                        @input="update('email', local.email)"></v-text-field>
                </div>
                <div>
                    <v-text-field v-model="local.birthdate" label="Birthdate" append-icon="mdi-cake-variant" 
                        v-mask="'##/##/####'" :error-messages="birthdateError"
                        hint="mm/dd/yyyy" persistent-hint @input="update('birthdate', local.birthdate)"></v-text-field>
                </div>
                <div>
                    <v-select v-model="local.gender" :items="genders" item-value="id" item-text="name" label="Gender" 
                        :error-messages="genderError" @change="update('gender', local.gender)"></v-select>
                </div>
                <div>
                    <v-text-field v-model="local.otherId" label="Other ID" :error-messages="otherIdError" required
                        @input="update('otherId', local.otherId)"></v-text-field>
                </div>
            </v-col>
            <v-col xs="12" lg="6" :class="$vuetify.breakpoint.smAndDown ? '' : 'pl-2'">
                <div>
                    <div class="subheadi2 grey--text">
                        Address
                    </div>
                </div>
                <div>
                    <v-text-field v-model="local.addressLine1" label="Address Line 1" :error-messages="addressLine1Error" required
                        @input="update('addressLine1', local.addressLine1)" maxlength="40"></v-text-field>
                </div>
                <div>
                    <v-text-field v-model="local.addressLine2" label="Address Line 2" required
                        @input="update('addressLine2', local.addressLine2)" maxlength="40"></v-text-field>
                </div>
                <div>
                    <v-row>
                        <v-col xs="12" md="5" class="pr-2">
                            <v-text-field v-model="local.addressCity" label="City" :error-messages="cityError" required
                                @input="update('addressCity', local.addressCity)"></v-text-field>
                        </v-col>
                        <v-col xs="6" md="3" class="px-2">
                            <v-text-field v-model="local.addressState" label="State" v-mask="'AA'" :error-messages="stateError" required
                                @input="update('addressState', local.addressState)"></v-text-field>
                        </v-col>
                        <v-col xs="6" md="4" class="pl-2">
                            <v-text-field v-model="local.addressZipcode" label="Zipcode" v-mask="'#####'" :error-messages="zipcodeError" required 
                                @input="update('addressZipcode', local.addressZipcode)"></v-text-field>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { cloneDeep, tap, set } from 'lodash'
import { mask } from 'vue-the-mask';

export default {
    props: {
        value           : { type: Object, default: () => { return {} } },
        valStrength     : { type: String, default: 'strong' },
        phoneRequired   : { type: Boolean, default: true },
    },
    data: () => ({
        customerTypes: [ 
            { id: '1', name: 'Person' },
            { id: '2', name: 'Company' }
        ],
        genders: [
            { id: 'm', name: 'Male' },
            { id: 'f', name: 'Female' },
            { id: 'n', name: 'Neutral' }
        ],
        birthdateError: '',
        customerTypeError: '',
        firstNameError: '',
        lastNameError: '',
        phoneError: '',
        emailError: '',
        genderError: '',
        addressLine1Error: '',
        cityError: '',
        stateError: '',
        zipcodeError: '',
        otherIdError: '',
    }),
    computed: {
        local() {
            return this.value ? this.value : { 
                birthdate: '',
                customerTypeId: 1,
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                gender: '',
                addressLine1: '',
                addressLine2: '',
                addressCity: '',
                addressState: '',
                addressZipcode: '',
                parentId: null,
                otherId: '',
            };
        },
        customer() {
            return {
                customerTypeId: this.local.customerTypeId,
                firstName: this.local.firstName,
                lastName: this.local.lastName,
                fullName: this.local.firstName.trim() + ' ' + this.local.lastName.trim(),
                phone: this.local.phone,
                email: this.local.email,
                birthdate: this.formatDate(this.local.birthdate),
                gender: this.local.gender,
                addressLine1: this.local.addressLine1,
                addressLine2: this.local.addressLine2,
                addressCity: this.local.addressCity,
                addressState: this.local.addressState,
                addressZipcode: this.local.addressZipcode,
                customerId: this.local.internalCustomerId,
                parentId: null,
                otherId: this.local.otherId,
            }
        }
    },
    methods: {
        isValid() {
            let errorFound = false;
            this.customerTypeError = '';
            this.firstNameError = this.lastNameError = this.phoneError = 
            this.emailError = this.birthdateError = this.genderError = 
            this.addressLine1Error = this.cityError = this.stateError = 
            this.zipcodeError = '';

            if( this.local.customerTypeId < 0 || this.local.customerTypeId > 2 ) {
                this.customerTypeError = 'required';
                errorFound = true;
            }

            if( this.local.firstName.length < 3 ) {
                this.firstNameError = 'required';
                errorFound = true;
            }

            if( this.local.lastName.length < 3 ) {
                this.lastNameError = 'required';
                errorFound = true;
            }

            if( this.phoneRequired && this.local.phone.length < 10 ) {
                this.phoneError = 'required';
                errorFound = true;
            }

            if( this.valStrength.toLowerCase() == 'strong' && ( this.local.email == null || ( this.local.email.length > 0 && !/.+@.+/.test(this.local.email) ) ) ) {
                this.emailError = 'required';
                errorFound = true;
            }

            if( this.valStrength.toLowerCase() == 'strong' && this.local.birthdate.length > 0 && !/^(((0)[0-9])|((1)[0-2]))([0-2][0-9]|(3)[0-1])\d{4}$/i.test(this.local.birthdate) ) {
                this.birthdateError = 'invalid format (must be mm/dd/yyyy)';
                errorFound = true;
            }
            else if( this.valStrength.toLowerCase() == 'strong' && this.local.birthdate.length > 0 && this.local.birthdate.substring(4, 8) > (new Date()).getFullYear() ) {
                this.birthdateError = 'birth year cannot be in the future';
                errorFound = true;
            }

            if( this.valStrength.toLowerCase() == 'strong' && this.local.addressLine1.length < 3 ) {
                this.addressLine1Error = 'required';
                errorFound = true;
            }

            if( this.valStrength.toLowerCase() == 'strong' && ( this.local.addressCity == undefined || this.local.addressCity.length < 2 ) ) {
                this.cityError = 'required';
                errorFound = true;
            }

            if( this.valStrength.toLowerCase() == 'strong' && this.local.addressState.length < 2 ) {
                this.stateError = 'required';
                errorFound = true;
            }

            if( this.valStrength.toLowerCase() == 'strong' && this.local.addressZipcode.length < 2 ) {
                this.zipcodeError = 'required';
                errorFound = true;
            }

            return !errorFound;
        },
        clearForm() {
            this.customerTypeError = '';
            this.firstNameError = this.lastNameError = this.phoneError = 
            this.emailError = this.birthdateError = this.genderError = 
            this.addressLine1Error = this.cityError = this.stateError = 
            this.zipcodeError = '';
            this.$emit('input', { 
                birthdate: '',
                customerTypeId: 1,
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                gender: '',
                addressLine1: '',
                addressLine2: '',
                addressCity: '',
                addressState: '',
                addressZipcode: '',
                parentId: null,
                otherId: '',
            });
        },
        capitalizeText(t) {
            if( t.length == 0 )
                return t;

            t = t.substring( 0, 1 ).toUpperCase() + t.substring( 1 );
            t = t.split(' ');
            for( var i = 0; i < t.length; i++ ) {
                if( t[i].length == 0 )
                    continue;

                t[i] = t[i].substring( 0, 1 ).toUpperCase() + t[i].substring( 1 ).toLowerCase();
            }

            return t.join(' ');
        },
        formatDate(b) {
            return b.substring(4, 8) + '-' + b.substring(0, 2) + '-' + b.substring(2, 4);
        },
        parseDate(b) {
            if( b == null || b == undefined )
                return '';

            return b.split('-')[1] + '/' + b.split('-')[2] + '/' + b.split('-')[0];
        },
        update(key, value) {
            if( key == 'firstName' || key == 'lastName' )
                value = this.capitalizeText(value);

            this.$emit('input', tap(cloneDeep(this.local), v => set(v, key, value)));
        },
    },
    directives: {
        mask
    }
}
</script>

<style>

</style>