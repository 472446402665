<template>
    <div>
        <v-list :dense="dense" v-if="dense">
            <v-list-item>
                <v-list-item-content class="py-0" id="oldPwd">
                    <v-text-field outlined :dense="dense" placeholder="Current password" :type="oldPasswordVisible ? 'text' : 'password'" v-model="oldPassword" hide-details
                        :append-icon="'mdi-eye' + (oldPasswordVisible ? '-off' : '')" @click:append="oldPasswordVisible = !oldPasswordVisible" @keydown.enter="$emit('login')" :error-messages="oldPasswordError">
                    </v-text-field>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content class="py-0" id="pwd">
                    <v-text-field outlined :dense="dense" placeholder="New password" :type="newPasswordVisible ? 'text' : 'password'" v-model="newPassword" hide-details
                        :append-icon="'mdi-eye' + (newPasswordVisible ? '-off' : '')" @click:append="newPasswordVisible = !newPasswordVisible" @input="pwdChange" @keydown.enter="$emit('login')">
                    </v-text-field>
                </v-list-item-content>
            </v-list-item>

            <v-list-item>
                <v-list-item-content class="py-0" id="pwdConfirm">
                    <v-text-field outlined :dense="dense" placeholder="Confirm new password" :type="newPasswordConfirmationVisible ? 'text' : 'password'" v-model="newPasswordConfirmation" :error-messages="newPasswordConfirmationError" 
                        :append-icon="'mdi-eye' + (newPasswordConfirmationVisible ? '-off' : '')" @click:append="newPasswordConfirmationVisible = !newPasswordConfirmationVisible" @input="pwdConfirmationChange" @keydown.enter="$emit('login')">
                    </v-text-field>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-show="missingRules.length > 0">
                <v-list-item-content class="py-0">
                    <ul class="caption error--text" v-html="missingRules"></ul>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <div v-else>
            <v-text-field outlined placeholder="New password" :type="newPasswordVisible ? 'text' : 'password'" v-model="newPassword" background-color="grey lighten-5"
                        :append-icon="'mdi-eye' + (newPasswordVisible ? '-off' : '')" @click:append="newPasswordVisible = !newPasswordVisible" @input="pwdChange" @keydown.enter="emit">
            </v-text-field>
            <v-text-field outlined placeholder="Confirm new password" :type="newPasswordConfirmationVisible ? 'text' : 'password'" v-model="newPasswordConfirmation" :error-messages="newPasswordConfirmationError" 
                        :append-icon="'mdi-eye' + (newPasswordConfirmationVisible ? '-off' : '')" @click:append="newPasswordConfirmationVisible = !newPasswordConfirmationVisible" @input="pwdConfirmationChange" 
                        @keydown.enter="emit" background-color="grey lighten-5">
            </v-text-field>
            <ul class="caption error--text" v-html="missingRules"></ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dense: {
            type: Boolean,
            default: false
        },
        minLength: {
            type: Number,
            default: 8
        },
        maxLength: {
            type: Number,
            default: 32
        },
        capitalRequired: {
            type: Boolean,
            default: true,
        },
        numberRequired: {
            type: Boolean,
            default: true
        },
        specialRequired: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        oldPassword                     : '',
        oldPasswordVisible              : false,
        oldPasswordError                : '',
        newPassword                     : '',
        newPasswordVisible              : false,
        newPasswordConfirmation         : '',
        newPasswordConfirmationError    : '',
        newPasswordConfirmationVisible  : false,

        missingRules                    : '',
    }),
    watch: {
     
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {

        },
        pwdChange() {
            this.validatePassword();
        },
        validatePassword() {
            this.missingRules = '';
            let isValid = true;

            if( this.newPassword.length < this.minLength ) {
                this.missingRules += '<li>Must be at least ' + this.minLength + ' characters long</li>';
                this.isValid = false;
            }
            if( this.newPassword.length > this.maxLength ) {
                this.missingRules += '<li>Must cannot be longer than ' + this.maxLength + ' characters</li>';
                this.isValid = false;
            }
            if( this.numberRequired && !/\d/.test(this.newPassword) ) {
                this.missingRules += '<li>Must contain numeric characters</li>';
                this.isValid = false;
            }
            if( this.capitalRequired && !/[A-Z]/.test(this.newPassword) ) {
                this.missingRules += '<li>Must contain capital letters</li>';
                this.isValid = false;
            }
            if( this.specialRequired && !/[@~!#$%^&*+=\-[\]\\;,/{}|\\:<>?]/.test(this.newPassword) ) {
                this.missingRules += '<li>Must contain at least one special character</li>';
                this.isValid = false;
            }

            return isValid;
        },
        pwdConfirmationChange( pwd ) {
            this.newPasswordConfirmationError = '';

            if( this.newPassword != pwd ) 
                this.newPasswordConfirmationError = 'password and confirmation don\'t match';
        },
        pwdConfirmationMatch() {
            return this.newPassword === this.newPasswordConfirmation;
        },
        clear() {
            this.oldPassword = this.newPassword = this.newPasswordConfirmation = this.newPasswordConfirmationError = this.missingRules = '';
        },
        isFormValid() {
            this.oldPasswordError = '';
            if( this.dense && this.oldPassword == '' )
                this.oldPasswordError = 'required';

            this.validatePassword();
            this.pwdConfirmationChange( this.newPasswordConfirmation );
            return this.newPasswordConfirmationError == '' && this.missingRules == '' && this.oldPasswordError == '';
        },
        emit() {
            if( this.isFormValid() ) 
                this.$emit('login');
        }
    },
}
</script>

<style scoped>
#oldPwd .v-input {
    margin-bottom: -1px;
    border-radius: 4px 4px 0 0;
}
#pwd .v-input {
    margin-bottom: -1px;
    border-top: none;
    border-radius: 0;
}
#pwdConfirm .v-input {
    margin-bottom: -1px;
    border-radius: 0 0 4px 4px;
}
</style>