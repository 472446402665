import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const stores = {
    namespaced: true,
    state: {
        stores      : [],
        needRefresh : true,
    },
    mutations: {
        setStores( state, payload ) {
            state.stores = payload;
        },
        setNeedRefresh( state, payload ) {
            state.needRefresh = payload;
        }
    },
    actions: {
        async loadStores({ commit, state }) {
            if( !state.needRefresh ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let res = await api.get('/admin/stores');

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setStores', res.data.data);
                commit('setNeedRefresh', false);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async saveStore({ commit }, store) {
            try {
                let api = new API();
                let res = null;
                
                if( _st.isNUE( store.id ) )
                    res = await api.post('/admin/store', store);
                else
                    res = await api.put('/admin/store', store);

                // if( !res.data.status ) {
                //     return Promise.resolve( res.data );
                // }

                commit('setNeedRefresh', true);
                return Promise.resolve( res );
            }
            catch(error) {
                return Promise.reject(error);
            }
        }
    },
    getters: {
        stores          : (state) => state.stores,
        activeStores    : (state) => state.stores.filter(s => s.active),
        needRefresh     : (state) => state.needRefresh,
    }
}