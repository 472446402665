<template>
    <v-card flat>
        <v-card-title class="pa-1" v-if="showForm">
            <v-row>
                <v-col>
                    <v-select v-model="type" :items="typeOptions" label="Type" item-text="value" item-value="id"></v-select>
                </v-col>
                <v-col>
                    <v-text-field v-model="identifier" label="Identifier"></v-text-field>
                </v-col>
                <v-col align-self="center">
                    <v-btn depressed @click="getHistory">Get History</v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-divider v-if="showForm"></v-divider>
        <v-card-text style="overflow: auto;" v-if="!loading && history != null && history.length > 0">
            <v-container>
                <v-timeline dense clipped align-top class="history">

                    <template v-for="(action, ix) in history">
                        <v-timeline-item small class="mb-3" color="grey" icon-color="grey lighten-2" :key="ix">
                            <v-row no-gutters justify="space-between">
                                <v-col cols="7">
                                    <div class="subtitle-1 grey--text text--darken-4">{{ action.action }}</div>
                                    <div class="caption">by {{ action.employee.fullName }}</div>
                                    <div class="caption" v-if="action.store.name != ''">at {{ action.store.name }}</div>
                                    <div class="caption">on {{ formatDate( action.date ) }}</div>
                                </v-col>
                                <v-col cols="5" text-right align-self-center>
                                    <v-btn depressed small color="primary" 
                                        @click="action.show = !action.show">{{ action.show ? 'Hide' : 'Show changes' }}</v-btn>
                                </v-col>
                            </v-row>
                            <div style="padding: 10px; border-radius: 5px; background-color: #f5f5f5;" class="mt-3" v-show="action.show === true">
                                <table style="width:100%;" v-if="action.changes.length > 0">
                                    <tr>
                                        <th style="text-align: left;">Value</th>
                                        <th style="text-align: left;">Changes</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                    <template v-for="(item, ix) in action.changes">
                                        <template v-for="(value, propertyName) in item">
                                            <tr :key="ix + propertyName">
                                                <td>{{ propertyName }}</td>
                                                <td>{{ value[0] }}</td>
                                                <td>→</td>
                                                <td>{{ value[1] }}</td>
                                            </tr>
                                        </template>
                                    </template>
                                </table>
                                <div v-else>No changes found</div>
                            </div>
                        </v-timeline-item>
                    </template>

                </v-timeline>
            </v-container>
        </v-card-text>

        <v-card-text v-else>
            <div class="subtitle" v-if="loading">
                Loading...
                <v-progress-linear :indeterminate="true"></v-progress-linear>
            </div>
            <div class="title" v-else>No history found</div>
        </v-card-text>
    </v-card>
</template>

<script>
import { API } from '@/inc/api';
import moment from 'moment';
import { _st } from '@/softech';

export default {
    props: {
        objType: { type: String, default: null },
        id: { type: String, default: null },
        showForm: { type: Boolean, default: true }
    },
    data() {
        return {
            identifier      : _st.isNU( this.id ) ? null : this.id,
            type            : _st.isNU( this.objType ) ? null : this.objType,
            typeOptions     : [
                { id: 'event', value: 'Event' },
                { id: 'eventparticipant', value: 'Event participant' },
                { id: 'customer', value: 'Customer' },
                { id: 'order', value: 'Order' },
                { id: 'reservation', value: 'Reservation' },
            ],

            history         : null,
            loading         : false,
        }
    },
    methods: {
        async getHistory() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get(`/history/${this.type}/${this.identifier}`);
                this.loading = false;
                
                if( res.data.status === false ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.history = res.data.data;
            }
            catch( error ) {
                this.loading = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        formatDate( d ) {
            return moment( d ).format( 'YYYY-MM-DD @ hh:mm a' );
        },
        clean() {
            this.history = null;
        }
    }
}
</script>

<style lang="scss">
.history.v-timeline .v-timeline-item__divider {
    min-width: 0;
}
.history.v-timeline::before {
    left: 10px !important;
}
.history.v-timeline .v-timeline-item__body {
    max-width: none;
    margin-left: 10px;
}
</style>