<template>
  <div id="app" v-cloak>
    <v-app>
      <v-navigation-drawer :clipped="$vuetify.breakpoint.lgAndUp" v-model="drawer" fixed :app="isLoggedIn" v-show="isLoggedIn">
        <v-toolbar flat class="transparent">
          <user-avatar v-if="isLoggedIn && user != null" :user="user" :additional-text="user.department + ': ' + user.position" 
            @logout="showLogoutWarning = true"></user-avatar>

          <div class="text-center stage" style="width:100%;" v-else>
            <v-btn @click="$router.push('/')" block color="error" class="bounce elevation-0">Login</v-btn>
          </div>
        </v-toolbar>
        <v-list>
          <v-divider></v-divider>
          <template v-for="item in items">
            <v-row v-if="item.heading" :key="'H' + item.heading" row align-center>
              <v-col cols="12" class="grey lighten-5">
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-col>
            </v-row>

            <v-list-group v-else-if="item.children" :value="item.model" :key="item.text" :prepend-icon="item.icon" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item v-for="(child, i) in item.children" :key="i" :to="child.to" link>
                <v-list-item-title v-text="child.text"></v-list-item-title>
                <v-list-item-icon>
                  <v-icon v-text="child.icon"></v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>

            <v-list-item v-else :key="item.text" :to="item.to" link>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </template>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="grey darken-4" dark :app="isLoggedIn" fixed v-show="isLoggedIn">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="ml-0"></v-app-bar-nav-icon>
        <v-toolbar-title class="text-left" style="width:100%">
          {{ title }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <finder v-model="finding" class="mr-2" style="width:50%;margin-top:-25px" ref="finder" super-search></finder>

        <v-divider dark vertical inset v-if="toolbarMenu.length > 0"></v-divider>

        <v-menu v-if="toolbarMenu.length > 0" offset-y transition="slide-y-transition" min-width="194">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <template v-for="item in toolbarMenu">
              <v-divider v-if="item.divider != undefined && item.divider" :key="item.id"></v-divider>
              <v-list-item :key="item.id" @click="item.action" v-else>
                <v-list-item-action>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="text-left">{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-main :class="(isLoggedIn ? 'fill-height' : '') + ' grey lighten-4'">
        <router-view :key="$route.path" />
      </v-main>

      <v-dialog v-model="showLogoutWarning" width="600" persistent>
				<v-card>
					<v-card-title>
						<div class="title text-xs-center">
							Are you sure?
						</div>
					</v-card-title>
					<v-card-text>
						<p>
							Are you sure you want to logout? Your current work will be lost
							and cannot be recovered.
						</p>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn depressed text @click="showLogoutWarning = false">Cancel</v-btn>
						<v-btn depressed text @click="logoutUser" color="red">Logout</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

      <v-dialog v-model="historyDialog" width="800" persistent>
				<v-card>
					<v-card-text style="overflow: auto; max-height: 75vh;">
						<history ref="history"></history>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn depressed @click="closeHistoryDialog">Close</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

      <v-dialog v-model="storeCodesDialog" width="500">
        <v-card>
          <v-card-title>Store codes</v-card-title>
          <v-card-subtitle>Generate new store codes</v-card-subtitle>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Store</th>
                    <th class="text-left">Expire</th>
                    <th class="text-left">Code</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(code, ix) in storeCodes" :key="ix">
                    <td>{{ code.store }}</td>
                    <td>{{ formatTime( code.date_expire ) }}</td>
                    <td>{{ code.code }}</td>
                    <td>
                      <v-btn icon @click="showSendDialog(code)"><v-icon>mdi-send</v-icon></v-btn>
                    </td>
                  </tr>
                  <tr v-if="storeCodes.length == 0">
                    <td colspan="3" class="text-center grey--text">
                      Click the button below to generate new codes
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
              <v-btn block depressed color="grey darken-4" class="white--text" 
                :loading="generatingCodes" @click="generateCodes">Generate codes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="sendDialog" width="400">
        <v-card>
          <v-card-title>Send code to...</v-card-title>
          <v-card-subtitle>Select an employee to send this code via text message</v-card-subtitle>
          <v-card-text>
            <v-select v-model="sendToUser" :items="users" item-text="fullName" item-value="id" outlined flat solo hide-details></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn block depressed color="grey darken-4" class="white--text" :loading="sending" @click="sendCode">
              Send
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="expressCustomerDialog" width="600" persistent>
				<v-card>
					<v-card-title>Express customer</v-card-title>
          <v-card-subtitle>Create new customers fast and easy</v-card-subtitle>
					<v-card-text>
						<div class="body-1 error--text" v-if="customerError != null">{{ customerError }}</div>
						<customer-basic-information
							ref="customerBasicInformation"
							:customerId="null"
							:parent-child="true"
							val-strength="basic"
							@saved="closeExpressCustomerDialog"
							@error="savingCustomerError"
							@endProcessing="customerSaving = false"
						/>
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click="closeExpressCustomerDialog" text>Cancel</v-btn>
						<v-btn :loading="customerSaving" @click="saveExpressCustomer" text>Save</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

      <alerts />
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { API } from '@/inc/api';
import Finder from '@/components/Finder.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import Alerts from '@/components/Alerts.vue';
import History from '@/components/History.vue';
import CustomerBasicInformation from '@/components/CustomerBasicInformation.vue';
import { bus } from '@/main';

export default {
  data() {
    return {
      title: 'LFA Admin Panel',
      drawer: null,
      items: [
        { icon: 'mdi-view-dashboard-variant', text: 'Dashboard', to: '/', model: false },
        {
          icon: 'mdi-store',
          text: 'Stores',
          to: '/stores',
          model: false
        },{
          icon: 'mdi-account',
          text: 'Users',
          to: '/users',
          model: false,
        },{
          icon: 'mdi-package-variant-closed',
          text: 'Products',
          to: '/products',
          model: false
        },{
          icon: 'mdi-sale',
          text: 'Promo codes',
          to: '/promo',
          model: false
        },{
          icon: 'mdi-finance',
          text: 'Reports',
          to: '/reports',
          model: false
        },{
          icon: 'mdi-account-circle',
          text: 'Customers',
          to: '/customers',
          model: false,
        },{
          icon: 'mdi-view-dashboard',
          text: 'Reservations',
          to: '/reservations',
          model: false,
        },{ 
          icon: 'mdi-format-list-bulleted', 
          text: 'Orders', 
          to: '/orders', 
          model: false 
        },{
          icon: 'mdi-calendar',
          text: 'Events',
          to: '/events',
          model: false,
        },{
          icon: 'mdi-account-plus',
          text: 'Leads',
          to: '/leads',
          model: false,
        },{
          icon: null,
          text: 'Open to buy',
          to: '/open-to-buy',
          model: false,
        },
      ],
      toolbarMenu: [
        {
          id: 1,
          text: 'Express Customer',
          icon: 'mdi-plus',
          action: this.openExpressCustomerDialog
        },{
          id: 2,
          text: 'History',
          icon: 'mdi-history',
          action: () => { this.historyDialog = true; },
        },
        { id: 3, divider: true },
        {
          id: 4,
          text: 'Store codes',
          icon: 'mdi-key-outline',
          action: this.storeCodesTool,
        },
      ],

      finding: false,
      historyDialog: false,
      storeCodesDialog: false,
      sendDialog: false,
      showLogoutWarning: false,
      generatingCodes: false,
      sending: false,

      storeCodes: [],
      selectedCode: null,
      sendToUser: null,

      expressCustomerDialog: false,
      customerError: null,
			customerSaving: false,
    }
  },

  computed: {
    ...mapGetters({
      user        : 'auth/user',
      isLoggedIn  : 'auth/isLoggedIn',
      users       : 'users/activeUsers'
    })
  },

  mounted() {
    bus.$on('login', () => {
      this.init() ;
    });
  },

  methods: {
    ...mapActions({
      authAction: 'auth/authAction',
      logout    : 'auth/logout',
      loadUsers : 'users/loadUsers'
    }),
    async init() {
      await this.authAction();
    },
    openExpressCustomerDialog() {
      this.expressCustomerDialog = true;
    },
    savingCustomerError(error) {
			this.customerError = error;
		},
    storeCodesTool() {
      this.storeCodesDialog = true;
    },
    logoutUser() {
      this.showLogoutWarning = false;
      this.logout();
    },
    closeHistoryDialog() {
			this.$refs.history.clean();
			this.historyDialog = false;
		},
    async generateCodes() {
      let api = new API();

      try {
        this.generatingCodes = true;
        let res = await api.post('/admin/store/codes', {});
        this.generatingCodes = false;

        if( res.data.status !== true ) {
          this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
          return false;
        }

        this.storeCodes = res.data.data;
      }
      catch(error) {
        this.generatingCodes = false;
        this.mxShowMessage( error, 'error' );
      }
    },
    async showSendDialog(code) {
      this.selectedCode = code;
      this.sendDialog = true;

      if( this.users.length == 0 )
        await this.loadUsers();
    },
    async sendCode() {
      if( this.selectedCode == null || this.sendToUser == null ) {
        this.mxShowMessage( 'Missing information', 'warning' );
        return;
      }

      let api = new API();

      try {
        this.sending = true;
        let res = await api.post('/admin/send/sms', {
          body: `El código para la tienda ${this.selectedCode.store} es: ${this.selectedCode.code} | exp: ${this.formatTime(this.selectedCode.date_expire)}`,
          userId: this.sendToUser
        });
        this.sending = false;

        if( res.data.status !== true ) {
          this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
          return false;
        }
        else {
          this.mxShowMessage( 'Message sent', 'success' );
          this.sendDialog = false;
          this.sendToUser = null;
          return false;
        }
      }
      catch(error) {
        this.sending = false;
        this.mxShowMessage( error, 'error' );
      }
    },
    closeExpressCustomerDialog() {
			this.expressCustomerDialog = false;
			this.customerSaving = false;
			this.$refs.customerBasicInformation.clearForm();
		},
    saveExpressCustomer() {
			if( this.$refs.customerBasicInformation.isValid() ) {
				this.customerSaving = true;
				this.$refs.customerBasicInformation.save();
			}
		},
    formatTime(d) {
      let date = new Date(d);
      return date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric'
      });
    }
  },
  components: {
    Finder, UserAvatar, Alerts, History, CustomerBasicInformation
  }
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  .v-btn {
    .v-btn__content {
      text-transform: none;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
