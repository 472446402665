import { API } from '@/inc/api';
import { bus } from '@/main';

export const items = {
    namespaced: true,
    state: {
        items           : [],
        needRefresh     : true,
        hasMore         : true,
        page            : 0,
        perPage         : 200,
    },
    mutations: {
        setItems( state, payload ) {
            if( state.needRefresh ) {
                state.items = payload;
            }
            else {
                payload.forEach(c => {
                    state.items.push(c);
                });
            }
        },
        setNeedRefresh( state, payload ) {
            state.needRefresh = payload;
        },
        setHasMore( state, payload ) {
            state.hasMore = payload;
        },
        setPage( state, payload ) {
            state.page = payload;
        },
        replaceItems( state, payload ) {
            state.items = payload;
        }
    },
    actions: {
        async loadItems({ commit, state }) {
            if( !state.needRefresh && !state.hasMore ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let offset = state.page * state.perPage;
                offset = offset == 0 ? 0 : offset + 1;

                let res = await api.get(`/admin/otb-items/${offset}/${state.perPage}`);

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setItems', res.data.data);
                commit('setNeedRefresh', false);
                commit('setHasMore', res.data.data.length == state.perPage);
                commit('setPage', state.page + 1);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject( error );
            }
        },
        async saveItem({ commit, state }, { productId, size, optTurns, ix }) {
            try {
                let api = new API();
                let res = await api.post('/admin/otb-item', { productId, size, optTurns });

                if( res.data.status !== true ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                if( ix > -1 ) {
                    let items = state.items;
                    Object.assign( items[ix], {
                        productId, 
                        size, 
                        opt_turn: optTurns,
                        description: items[ix].description,
                        style: items[ix].style
                    });
                    commit('replaceItems', items);
                } 

                return Promise.resolve();
            }
            catch( error ) {
                return Promise.reject( error );
            }
        },
        async saveItems({ commit, state }, { items, optTurns }) {
            try {
                let localItems = [];
                items.forEach(i => localItems.push({ productId: i.productId, size: i.size }));

                let api = new API();
                let res = await api.post('/admin/otb-items', { items: localItems, optTurns });

                if( res.data.status !== true ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                let storeItems = state.items;
                localItems.forEach(i => {
                    let ix = storeItems.findIndex(si => si.productId == i.productId && si.size == i.size );
                    
                    if( ix > -1 )
                        storeItems[ix].opt_turn = optTurns;
                });

                commit('replaceItems', storeItems);

                return Promise.resolve();
            }
            catch( error ) {
                return Promise.reject( error );
            }
        }
    },
    getters: {
        items           : (state) => state.items,
        needRefresh     : (state) => state.needRefresh,
        hasMore         : (state) => state.hasMore,
        page            : (state) => state.page
    }
}