import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const products = {
    namespaced: true,
    state: {
        products    : [],
        needRefresh : true,
    },
    mutations: {
        setProducts( state, payload ) {
            state.products = payload;
        },
        setNeedRefresh( state, payload ) {
            state.needRefresh = payload;
        }
    },
    actions: {
        async loadProducts({ commit, state }) {
            if( !state.needRefresh ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let res = await api.get('/admin/products');

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setProducts', res.data.data);
                commit('setNeedRefresh', false);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async saveProduct({ commit }, {product, newImg}) {
            try {
                let api = new API();
                let res = null;
                
                if( _st.isNUE( product.id ) )
                    res = await api.post('/admin/product', {
                        ...product,
                        newImg
                    });
                else
                    res = await api.put('/admin/product', {
                        ...product,
                        newImg
                    });

                if( !res.data.status ) {
                    return Promise.resolve( res.data );
                }

                commit('setNeedRefresh', true);
                return Promise.resolve( res.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        }
    },
    getters: {
        products        : (state) => state.products,
        activeProducts  : (state) => state.products.filter(p => p.active == true),
        needRefresh     : (state) => state.needRefresh,
    }
}