<template>
    <div class="alertWrapper">
        <template v-for="(alert, ix) in alerts">
            <v-snackbar :value="alert.value" bottom right :timeout="alert.timeout" :color="alert.color" :key="ix"
                @input="alert.value = false"> <!-- :class="alerts.length > 1 && ix > 0 ? 'mt-3' : ''" -->
                {{ alert.text }}
                <template v-slot:action="{ attrs }">
                    <v-btn depressed icon @click="alert.value = false" v-bind="attrs">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </template>
    </div>
</template> 

<script>
import { bus } from '@/main';
export default {    
    data() {
        return {
            alerts: []
        }
    },
    mounted() {

        bus.$on( 'alert', (alert) => {
            this.alerts.push({
                ...alert,
                value: true
            });
        });
    },
}
</script>

<style scoped>
/* .alertWrapper {
    position: absolute;
    bottom: 0;
    right: 10px;
    height: auto;
}
.alertWrapper .v-snack {
    position: initial;
    margin: 10px 5px;
} */
</style>