import { API } from '@/inc/api';

export const promo = {
    namespaced: true,
    state: {
        promoCodes  : [],
        needRefresh : true,
    },
    mutations: {
        setPromoCodes( state, payload ) {
            state.promoCodes = payload;
        },
        setNeedRefresh( state, payload ) {
            state.needRefresh = payload;
        }
    },
    actions: {
        async loadPromoCodes({ commit, state }) {
            if( !state.needRefresh ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let res = await api.get('/admin/promo-codes');

                if( !res.data.status ) {
                    return Promise.reject( res.data );
                }

                commit('setPromoCodes', res.data.data);
                commit('setNeedRefresh', false);
                return Promise.resolve( res.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async savePromoCode({ commit }, {promoCode, isEdit}) {
            try {
                let api = new API();
                let res = null;
                
                if( isEdit )
                    res = await api.put('/admin/promo-code', promoCode);
                else
                    res = await api.post('/admin/promo-code', promoCode);

                if( !res.data.status ) {
                    return Promise.resolve( res.data );
                }

                commit('setNeedRefresh', true);
                return Promise.resolve( res.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        }
    },
    getters: {
        promoCodes      : (state) => state.promoCodes,
        needRefresh     : (state) => state.needRefresh,
    }
}
