import { API } from '@/inc/api';
import { _st } from '@/softech';
import { bus } from '@/main';

export const extraItems = {
    namespaced: true,
    state: {
        extraItems  : [],
        needRefresh : true,
    },
    mutations: {
        setExtraItems( state, payload ) {
            state.extraItems = payload;
        },
        setNeedRefresh( state, payload ) {
            state.needRefresh = payload;
        }
    },
    actions: {
        async loadExtraItems({ commit, state }) {
            if( !state.needRefresh ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let res = await api.get('/admin/extra-items');

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setExtraItems', res.data.data);
                commit('setNeedRefresh', false);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
        async saveExtraItems({ commit }, product) {
            try {
                let api = new API();
                let res = null;
                
                if( _st.isNUE( product.id ) )
                    res = await api.post('/admin/extra-items', product);
                else
                    res = await api.put('/admin/extra-items', product);

                if( !res.data.status ) {
                    return Promise.resolve( res.data );
                }

                commit('setNeedRefresh', true);
                return Promise.resolve( res.data );
            }
            catch(error) {
                return Promise.reject(error);
            }
        }
    },
    getters: {
        extraItems      : (state) => state.extraItems,
        needRefresh     : (state) => state.needRefresh,
    }
}