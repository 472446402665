import { API } from '@/inc/api';
// import { _st } from '@/softech';
import { bus } from '@/main';

export const reservations = {
    namespaced: true,
    state: {
        reservations    : [],
        needRefresh     : true,
        hasMore         : true,
        page            : 0,
        perPage         : 200,
    },
    mutations: {
        setReservations( state, payload ) {
            if( state.needRefresh ) {
                state.reservations = payload;
            }
            else {
                payload.forEach(c => {
                    state.reservations.push(c);
                });
            }
        },
        setNeedRefresh( state, payload ) {
            state.needRefresh = payload;
        },
        setHasMore( state, payload ) {
            state.hasMore = payload;
        },
        setPage( state, payload ) {
            state.page = payload;
        }
    },
    actions: {
        async loadReservations({ commit, state }) {
            if( !state.needRefresh && !state.hasMore ) {
                return Promise.resolve();
            }

            try {
                let api = new API();
                let offset = state.page * state.perPage;
                offset = offset == 0 ? 0 : offset + 1;

                let res = await api.get(`/admin/reservations/${offset}/${state.perPage}`);

                if( !res.data.status ) {
                    bus.$emit('alert', { 
                        text    : res.data.message, 
                        color   : res.data.error ? 'error' : 'warning'
                    });
                    return Promise.reject( false );
                }

                commit('setReservations', res.data.data);
                commit('setNeedRefresh', false);
                commit('setHasMore', res.data.data.length == state.perPage);
                commit('setPage', state.page + 1);
                return Promise.resolve();
            }
            catch(error) {
                return Promise.reject(error);
            }
        },
    },
    getters: {
        reservations    : (state) => state.reservations,
        needRefresh     : (state) => state.needRefresh,
        hasMore         : (state) => state.hasMore,
        page            : (state) => state.page
    }
}