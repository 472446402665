<template>
    <div>
        <div v-if="parentChild && !child">
            <v-tabs v-model="active">
                <v-tab><span class="body-1">Regular Customer</span></v-tab>
                <v-tab><span class="body-1">Child Customer</span></v-tab>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <regular-customer-information v-model="localCustomer" :val-strength="valStrength" ref="regularCustomer" :phone-required="phoneRequired"></regular-customer-information>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <child-customer-information v-model="localCustomer" :val-strength="valStrength" ref="childCustomer" :parent-search="parentSearch"></child-customer-information>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </div>
        <div v-else-if="!parentChild && !child">
            <regular-customer-information v-model="localCustomer" :val-strength="valStrength" ref="regularCustomer" :phone-required="phoneRequired"></regular-customer-information>
        </div>
        <div v-else-if="!parentChild && child">
            <child-customer-information v-model="localCustomer" :val-strength="valStrength" ref="childCustomer" :parent-search="parentSearch"></child-customer-information>
        </div>
        <v-dialog v-model="updateDialog" width="400">
            <v-card>
                <v-card-text>
                    <p class="title text-xs-center font-weight-light">Warning</p>
                    <p class="text-xs-center">{{ dialogMessage }} Do you want to update it?</p>

                    <div class="grey lighten-3 pa-3" style="border-radius: 10px;" v-if="relatedCustomer != null">
                        <v-list dense class="transparent pt-0">
                            <v-list-item>
                                <v-list-item-content class="headline">{{ relatedCustomer.fullName }}</v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="relatedCustomer.phone != undefined && relatedCustomer.phone.length > 0">
                                <v-list-item-content>{{ this.formatPhoneNum(relatedCustomer.phone) }}</v-list-item-content>
                                <v-list-item-action>
                                    <v-icon>phone</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-if="relatedCustomer.parent != undefined && relatedCustomer.parent.phone != undefined && relatedCustomer.parent.phone.length > 0">
                                <v-list-item-content>{{ this.formatPhoneNum(relatedCustomer.parent.phone) }}</v-list-item-content>
                                <v-list-item-action>
                                    <v-icon>phone</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-if="relatedCustomer.email != undefined && relatedCustomer.email.length > 0">
                                <v-list-item-content>{{ relatedCustomer.email }}</v-list-item-content>
                                <v-list-item-action>
                                    <v-icon>mail_outline</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-if="relatedCustomer.parent != undefined && relatedCustomer.parent.email != undefined && relatedCustomer.parent.email.length > 0">
                                <v-list-item-content>{{ relatedCustomer.parent.email }}</v-list-item-content>
                                <v-list-item-action>
                                    <v-icon>mail_outline</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-if="relatedCustomer.otherId != undefined && relatedCustomer.otherId.length > 0">
                                <v-list-item-content>{{ relatedCustomer.otherId }}</v-list-item-content>
                                <v-list-item-action>
                                    <v-icon>mdi-account-card-details-outline</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cancelUpdate">Cancel</v-btn>
                    <v-btn @click="updateCustomer" color="grey darken-4" dark>Update</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { _st } from '@/softech';
import RegularCustomerInformation from '@/components/RegularCustomerInformation.vue';
import ChildCustomerInformation from '@/components/ChildCustomerInformation.vue';
import { API } from '@/inc/api';

export default {
    props: {
        value           : { type: Object, default() { return {} } }, // customer object
        customerId      : { type: String, default: '0' },
        valStrength     : { type: String, default: 'strong' },
        child           : { type: Boolean, default: false },
        parentChild     : { type: Boolean, default: false },
        parentSearch    : { type: Boolean, default: true },
        phoneRequired   : { type: Boolean, default: true },
    },
    data() {
        return {
            parentId            : null,
            internalCustomerId  : null,
            updateDialog        : false,
            dialogMessage       : '',
            relatedCustomer     : null,
            tempCustomerId      : null,
            active              : 0,
            localCustomer       : _st.isNU( this.customer ) ? null : this.loadCustomer( this.customer ),
        }
    },
    computed: {
        
    },
    mounted() {
        if( _st.isNUE( this.localCustomer ) && !_st.isNUE( this.customerId ) )
            this.load();
    },
    watch: {
        value(v) {
            if( !_st.isNU( v ) ) 
                this.localCustomer = this.loadCustomer( v );
        },
        customerId() {
            this.load();
        },
        localCustomer(v) {
            this.$emit('input', v);
        },
    },
    methods: {
        load() {
            if( this.customerId === '0' )
                return;

            // load customer information
            this.$http
                .get( '/customer/' + this.customerId ) 
                .then( response => { 
                    if( response.status === 401 ) {
                        let obj = this;
                        this.$root.$el.__vue__.$once('login', function() {
                            obj.load();
                        });
                        return;
                    }

                    if( !response.data.status && response.data.error ) {
                        this.mxShowMessage( 'ERROR: ' + response.data.message, 'error' );
                    }

                    if( !response.data.status )
                        return;

                    this.localCustomer = response.data.data;
                });
        },
        isValid() {
            if( this.parentChild ) {
                if( this.active == 0 ) { // regular customer
                    // this.localCustomer = this.$refs.regularCustomer.customer;
                    return this.$refs.regularCustomer.isValid();
                }
                else { // child customer
                    // this.localCustomer = this.$refs.childCustomer.customer;
                    return this.$refs.childCustomer.isValid();
                }
            }
            else if( !this.parentChild && !this.child ) {
                // this.localCustomer = this.$refs.regularCustomer.customer;
                return this.$refs.regularCustomer.isValid();
            }
            else if( !this.parentChild && this.child ) {
                // this.localCustomer = this.$refs.childCustomer.customer;
                return this.$refs.childCustomer.isValid();
            }
        },
        formatDate(b) {
            return b.substring(4, 8) + '-' + b.substring(0, 2) + '-' + b.substring(2, 4);
        },
        parseDate(b) {
            if( _st.isNU( b ) )
                return '';
            if( !b.includes( '-' ) )
                return b;

            return b.split('-')[1] + '/' + b.split('-')[2] + '/' + b.split('-')[0];
        },
        async save() {
            if( !_st.isNUE( this.localCustomer.parentId ) ) { 
                this.saveChild( this.localCustomer.parentId );
                return;
            }

            // save the customer
            let api = new API();

            try {
                let res = await api.post('/admin/customer/express', this.localCustomer);
                this.$emit('endProcessing');

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.localCustomer.id = res.data.data.id;
                this.$emit('saved', this.localCustomer.id);
                this.mxShowMessage('Customer saved', 'success');
            }
            catch(error) {
                this.saving = false;
                this.mxShowMessage(error, 'error');
            }

            // --------------------

            // let method = _st.isNUE( this.localCustomer.id ) ? 'POST' : 'PUT'
            // let axiosOptions = {
            //     method: method,
            //     url: '/customer' + ( _st.isNUE( this.localCustomer.id ) ? '' : '/' + this.localCustomer.id ),
            //     data: {
            //         customer: {
            //             type: this.localCustomer.customerTypeId,
            //             first_name: this.localCustomer.firstName,
            //             last_name: this.localCustomer.lastName,
            //             full_name: '',
            //             birthdate: this.formatDate( this.localCustomer.birthdate ),
            //             phone: this.localCustomer.phone == '' ? null : this.localCustomer.phone,
            //             email: this.localCustomer.email,
            //             gender: this.localCustomer.gender,
            //             address_line_1: this.localCustomer.addressLine1,
            //             address_line_2: this.localCustomer.addressLine2,
            //             address_city: this.localCustomer.addressCity,
            //             address_state: this.localCustomer.addressState,
            //             address_zipcode: this.localCustomer.addressZipcode,
            //             other_id: this.localCustomer.otherId,
            //         }
            //     },
            //     config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' } }
            // };

            // this.$http(axiosOptions)
            // .then( response => { 
            //     if( response.status === 401 ) {
            //         let obj = this;
            //         this.$root.$el.__vue__.$once('login', function() {
            //             obj.save();
            //         });
            //         return;
            //     }

            //     this.$emit('endProcessing');
            //     if(!response.data.status && response.data.error) {
            //         this.$emit('error', response.data.message);
            //         return;
            //     }

            //     // Customer already exist.
            //     if( !response.data.status && !response.data.error && response.data.code == '502' ) {
            //         this.dialogMessage = 'The customer below already exist with the same phone number.';
            //         this.relatedCustomer = response.data.data;
            //         this.updateDialog = true;
            //         return;
            //     }
            //     else if ( !response.data.status && !response.data.error && response.data.code == '501' ) {
            //         this.dialogMessage = 'The customer below already exist with the same email address.'; // response.data.message;
            //         this.relatedCustomer = response.data.data;
            //         this.updateDialog = true;
            //         return;
            //     }

            //     if( !response.data.status && !response.data.error ) {
            //         this.$emit('error', 'Error saving');
            //         this.$emit('endProcessing');
            //     }

            //     if( method == 'POST' )
            //         this.localCustomer.id = response.data.customerId;

            //     this.$emit('saved', this.localCustomer.id);
            // })
            // .catch(function (error) {
            //     this.$emit('error', error);
            //     console.log(error);
            // })
            // .finally(function () {
            //     // this.$emit('endProcessing');
            // });
        },
        async saveChild() {
            // save the child customer
            let api = new API();

            try {
                let res = await api.post('/admin/customer/child', {
                    parentId: this.localCustomer.parentId,
                    child: {
                        firstName   : this.localCustomer.firstName,
                        lastName    : this.localCustomer.lastName,
                        birthdate   : this.formatDate( this.localCustomer.birthdate ),
                        gender      : this.localCustomer.gender
                    }
                });
                this.$emit('endProcessing');

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                this.localCustomer.id = res.data.data.id;
                this.$emit('saved', this.localCustomer.id);
                this.mxShowMessage('Customer saved', 'success');
            }
            catch(error) {
                this.saving = false;
                this.mxShowMessage(error, 'error');
            }

            // ---------

            // let method = _st.isNUE( this.localCustomer.id ) ? 'POST' : 'PUT';
            // let axiosOptions = {
            //     method: method,
            //     url: '/customer/' + this.localCustomer.parentId + '/child' + ( _st.isNUE( this.localCustomer.id ) ? '' : '/' + this.localCustomer.id ),
            //     data: {
            //         customer: {
            //             firstName: this.localCustomer.firstName,
            //             lastName: this.localCustomer.lastName,
            //             fullName: '',
            //             birthdate: this.formatDate( this.localCustomer.birthdate ),
            //             gender: this.localCustomer.gender
            //         }
            //     },
            //     config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' } }
            // };

            // this.$http(axiosOptions)
            // .then( response => { 
            //     if( response.status === 401 ) {
            //         let obj = this;
            //         this.$root.$el.__vue__.$once('login', function() {
            //             obj.saveChild();
            //         });
            //         return;
            //     }

            //     this.$emit('endProcessing');
            //     if(!response.data.status && response.data.error) {
            //         this.$emit('error', response.data.message);
            //         return;
            //     }

            //     if( !response.data.status && !response.data.error && response.data.message == 'Customer already exist.' )  {
            //         this.dialogMessage = 'A customer already exist with the same phone or email.';
            //         this.updateDialog = true;
            //         this.tempCustomerId = response.data.customerId;
            //         return;
            //     }

            //     if( !response.data.status && !response.data.error ) {
            //         this.$emit('error', 'Error saving');
            //         this.$emit('endProcessing');
            //     }

            //     if( method == 'POST' )
            //         this.localCustomer.id = response.data.customerId;

            //     this.$emit('saved', this.internalCustomerId);
            // })
            // .catch(function (error) {
            //     this.$emit('error', error);
            //     console.log(error);
            // })
            // .finally(function () {
            //     // this.$emit('endProcessing');
            // });
        },
        updateCustomer() {
            this.updateDialog = false;
            this.localCustomer.id = this.relatedCustomer.id;
            this.save();
        },
        cancelUpdate() {
            this.updateDialog = false;
            this.$emit('endProcessing');
        },
        clearForm() {
            if( this.$refs.regularCustomer != undefined )
                this.$refs.regularCustomer.clearForm();
            if( this.$refs.childCustomer != undefined )
                this.$refs.childCustomer.clearForm();
                
            this.internalCustomerId = null;
        },
        capitalizeText(t) {
            if( t.length == 0 )
                return t;

            t = t.substring( 0, 1 ).toUpperCase() + t.substring( 1 );
            t = t.split(' ');
            for( var i = 0; i < t.length; i++ ) {
                if( t[i].length == 0 )
                    continue;

                t[i] = t[i].substring( 0, 1 ).toUpperCase() + t[i].substring( 1 ).toLowerCase();
            }

            return t.join(' ');
        },
        loadCustomer(c) {
            let customer = c;
            customer.birthdate = this.parseDate(customer.birthdate);

            this.localCustomer = customer;
            return customer;
        }
    },
    components: { 
        RegularCustomerInformation, ChildCustomerInformation
    }
}
</script>

<style>
</style>