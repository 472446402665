<template>
    <div>
        <v-autocomplete v-model="select" :loading="searchLoading" :items="searchResults" :search-input.sync="searchQuery" 
            flat hide-no-data hide-details label="Search" solo-inverted no-data-text="No results found" :filter="customFilter"
            class="mr-2 mt-6" @blur="onSearchBlur" @change="onOptionSelected" ref="search" v-if="superSearch" :disabled="disabled" :allow-overflow="false">

            <template v-slot:item="data">
                <template v-if="(typeof data.item) !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                    <v-list-item-avatar>
                        <v-avatar>
                        <v-icon v-if="data.item.category.toLowerCase() == 'customer'">mdi-account-circle</v-icon>
                        <v-icon v-if="data.item.category.toLowerCase() == 'event'">mdi-calendar</v-icon>
                        <v-icon v-if="data.item.category.toLowerCase() == 'reservation'">mdi-view-dashboard</v-icon>
                        <v-icon v-if="data.item.category.toLowerCase() == 'order'">mdi-currency-usd</v-icon>
                        <v-icon v-if="data.item.category.toLowerCase() == 'user'">mdi-account</v-icon>
                        <v-icon v-if="data.item.category.toLowerCase() == 'product'">mdi-package-variant-closed</v-icon>
                        <v-icon v-if="data.item.category.toLowerCase() == 'store'">mdi-store</v-icon>
                        <v-icon v-if="data.item.category.toLowerCase() == 'promo'">mdi-sale</v-icon>
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.line1"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.line2"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="pt-0">
                        <div class="grey--text" v-if="data.item.action != undefined">{{ data.item.action }}</div>
                        <div class="grey--text caption" v-if="data.item.action2 != undefined">{{ data.item.action2 }}</div>
                    </v-list-item-action>
                </template>
            </template>  
        </v-autocomplete>

        <v-row class="fill-height">
            <v-col xs="12" :class="showNewCustomerButton ? 'md8 lg8 xl8' : 'md12 lg12 xl12'">
                <v-text-field outlined :label="label" :append-icon="appendIcon" :append-outer-icon="appendOuterIcon" 
                    @click:append="findOnly" @click:append-outer="findOnly" single-line v-if="!superSearch" hide-details
                    @keyup.enter="findOnly" v-model="find" :loading="searchLoading" :disabled="disabled"></v-text-field>
            </v-col>
            <v-col xs="12" md="1" class="text-center" v-if="showNewCustomerButton">
                <v-divider vertical style="min-height:15%;" v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
                <div class="subheading" :style="$vuetify.breakpoint.smAndDown ? 'margin-top:-13px;' : ''">OR</div>
                <v-divider vertical style="min-height:15%;" v-if="$vuetify.breakpoint.mdAndUp"></v-divider>
            </v-col>
            <v-col xs="12" md="2" v-if="showNewCustomerButton">
                <v-btn large :block="$vuetify.breakpoint.smAndDown" @click="newCustomerDialog = true" :disabled="disabled"><v-icon>perm_identity</v-icon> Create Customer</v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="customerSelectionDialog" persistent scrollable width="500">
            <v-card>
                <v-card-title>Select a customer</v-card-title>
                <v-card-subtitle>Multiple results found. Please select on of the list below.</v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text style="max-height: 350px;" class="pa-0 ma-0">
                    <v-list three-line>
                        <template v-for="c in customers">
                            <v-list-item :key="c.customerId">
                                <v-list-item-content>
                                    <v-list-item-title>{{ c.fullName }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="c.parent != undefined && c.parent != null">
                                        <i><b>Parent</b>: {{ c.parent.fullName }}</i>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="c.parent != undefined && c.parent != null">
                                        {{ formatPhoneNum(c.parent.phone) }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>{{ formatPhoneNum(c.phone) }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ c.email }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn small @click="selectCustomer(c)" :disabled="c.selected">Select</v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </template>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="closeCustomerSelectionDialog" color="grey darken-4" class="white--text">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Vue from 'vue'
import { API } from '@/inc/api';
import moment from 'moment';
import { _st } from '@/softech';

export default {
    props: {
        value                   : { type: Boolean, default: false },
        searchCategory          : { type: String, default: '' },
        superSearch             : { type: Boolean, default: false, },
        returnFirst             : { type: Boolean, default: false, },
        label                   : { type: String, default: 'Search' },
        appendIcon              : { type: String, default: '' },
        appendOuterIcon         : { type: String, default: '' },
        disabled                : { type: Boolean, default: false, },

        // prop for showing a dialog box when found more than one customer
        showMultipleCustomers   : {  type: Boolean, default: true, },
        showNewCustomerButton   : { type: Boolean, default: false, },
        eventForReservationPage : { type: Boolean, default: false },

        // prop that indicate if the customer phone number is required when creating a new one.
        customerPhoneRequired   : { type: Boolean, default: true }
    },
    data: () => ({
        categories: ['customers','orders','events','reservations','products','users','payments','stores'],
        find: null,
        select: null,
        searchQuery: null,
        searchResults: [],
        searchLoading: false,
        axiosController: null,
        customer: {
            fullName: '',
            addressLine1: '',
            phone: '',
            email: '',
        },
        customerDialog: false,
        order: {},
        orderDialog: false,
        event: {},
        eventDialog: false,
        reservation: {},
        reservationDialog: false,
        customers: null,
        customerSelectionDialog: false,
        newCustomerDialog: false,
        customerError: null,
        customerSaving: false,
    }),

    watch: {
        searchQuery( val ) {
            val && val !== this.select && this.querySelections(val)
        }
    },
    methods: {
        onSearchBlur() { 
            this.searchLoading = false;
            this.searchResults = [];
        },
        onOptionSelected() { 
            // do action
            switch(this.select.category.toLowerCase()) {
                case 'customer':
                    this.$router.push(`/customers/${this.select.data.id}`);
                    break;
                case 'order':
                    this.$router.push(`/orders/${this.select.data.id}`);
                    break;
                case 'event':
                    this.$router.push(`/events/${this.select.data.id}`);
                    break;
                case 'reservation':
                    this.$router.push(`/reservations/${this.select.data.id}`);
                    break;
                case 'user':
                    this.$router.push(`/users/${this.select.data.id}`);
                    break;
                case 'product':
                    this.$router.push(`/products/${this.select.data.id}`);
                    break;
                case 'store':
                    this.$router.push(`/stores/${this.select.data.id}`);
                    break;
                case 'promo':
                    this.$router.push(`/promo/${this.select.data.code}`);
                    break;
                default:
                    break;
            }

            // Clean search
            this.searchResults = [];
            this.searchQuery = '';
            this.searchLoading = false;
            this.$refs.search.blur(); // lose focus of the phone field
        },
        customFilter() { 
            return true;
        },
        realQuery: function(query) {
            
            if( this.searchCategory.length > 0 ) {
                switch(this.searchCategory.toLowerCase()) {
                    case 'customers': query = 'c-' + query; break;
                    case 'events': query = 'e-' + query; break;
                    case 'orders': query = 'o-' + query; break;
                    case 'reservations': query = 'r-' + query; break;
                }
            }

            return query;
        },
        async querySelections (v) {
            const regex = /([ceroCERO]{1}-)(.+)|.+/gm;
            
            let m = regex.exec(v);
            
            // See if there is any search prefix
            // c- is for searching a customer
            // e- is for searching an event
            // r- is for searching a reservation
            // o- is for searching an order
            if ( m[1] != undefined ) {
                if(m[2].length < 2 && m[2].length % 2 != 0)
                return;

                this.searchLoading = true;
                switch(m[1].toLowerCase()) {
                    case 'c-': this.findCustomer(m[2]); return;
                    case 'e-': this.findEvent(m[2]); return;
                    case 'r-': this.findReservation(m[2]); return;
                    case 'o-': this.findOrder(m[2]); return;
                }
            }

            if(v.length < 2 && v.length % 2 != 0)
                return;

            let api = new API();

            api.cancelRequest( this.axiosController );
            this.axiosController = api.controller;

            try {
                this.$emit('input', true);
                this.searchLoading = true;
                let res = await api.get(`/admin/search/${v}`);

                if( !res.data.status && res.data.error ) {
                    this.mxShowMessage( res.data.message, 'error' );
                }

                if( !res.data.status && !res.data.error ) {
                    this.searchLoading = false;
                    this.axiosController = null;
                    return;
                }

                this.searchResults = [];

                // customers
                if( res.data.data.customers.length > 0 ) {
                    this.searchResults.push({ header: 'Customers' });
                    res.data.data.customers.forEach(c => {
                        this.searchResults.push({ 
                            id: c.customerId, 
                            line1: c.fullName, 
                            line2: this.formatPhoneNum(c.phone), 
                            category: c.category,
                            data: c });
                    });
                }

                // events
                if( res.data.data.events.length > 0 ) {
                    this.searchResults.push({ header: 'Events' });
                    res.data.data.events.forEach(c => {
                        this.searchResults.push({ 
                            id: c.eventId, 
                            line1: c.occasion, 
                            line2: `#${c.foreignKey} - ${c.eventType}`, 
                            category: c.category, 
                            action: moment(c.eventDate).format('MMM DD, YYYY'),
                            data: c });
                    });
                }

                // reservations
                if( res.data.data.reservations.length > 0 ) {
                    this.searchResults.push({ header: 'Reservations' });
                    res.data.data.reservations.forEach(c => {
                        this.searchResults.push({ 
                            id: c.id, 
                            line1: c.customer.fullName, 
                            line2: c.status, 
                            category: c.category, 
                            action: moment(c.useDate).format('MMM DD, YYYY'),
                            data: c });
                    });
                }

                // orders
                if( res.data.data.orders.length > 0 ) {
                    this.searchResults.push({ header: 'Orders' });
                    res.data.data.orders.forEach(c => {
                        this.searchResults.push({ 
                            id: c.orderId, 
                            line1: c.customer.fullName, 
                            line2: c.foreignId.length > 0 ? c.foreignId : c.orderId, 
                            category: c.category, 
                            action: this.formatMoney(c.balance), 
                            action2: moment(c.orderDate).format('YYYY-MM-DD'),
                            data: c });
                    });
                }

                // users
                if( res.data.data.users.length > 0 ) {
                    this.searchResults.push({ header: 'Users' });
                    res.data.data.users.forEach(c => {
                        this.searchResults.push({ 
                            id: c.id, 
                            line1: c.fullName, 
                            line2: c.position, 
                            category: c.category,
                            data: c });
                    });
                }

                // products
                if( res.data.data.products.length > 0 ) {
                    this.searchResults.push({ header: 'Products' });
                    res.data.data.products.forEach(c => {
                        this.searchResults.push({ 
                            id: c.id, 
                            line1: c.style, 
                            line2: c.description, 
                            category: c.category,
                            data: c });
                    });
                } 

                // stores
                if( res.data.data.stores.length > 0 ) {
                    this.searchResults.push({ header: 'Stores' });
                    res.data.data.stores.forEach(c => {
                        this.searchResults.push({ 
                            id: c.id, 
                            line1: c.name, 
                            line2: _st.toPhoneNumber( c.phone ), 
                            action: c.wintuxStoreId, 
                            category: c.category,
                            data: c });
                    });
                } 

                // promo codes
                if( res.data.data.promos.length > 0 ) {
                    this.searchResults.push({ header: 'Promos' });
                    res.data.data.promos.forEach(c => {
                        this.searchResults.push({ 
                            id: c.code, 
                            line1: c.code, 
                            line2: c.description, 
                            category: c.category,
                            data: c });
                    });
                } 

                this.searchLoading = false;
                this.axiosController = null;
                this.$emit('input', false);
            }
            catch(error) {
                if( error.message != undefined && error.message == 'canceled' )
                    return;

                this.mxShowMessage(error, 'error');
            }
        },
        async findOnly() { 
            let q = this.realQuery(this.find);

            const regex = /([ceroCERO]{1}-)(.+)|.+/gm;
            let m = regex.exec(q);
            
            // See if there is any search prefix
            // c- is for searching a customer
            // e- is for searching an event
            // r- is for searching a reservation
            // o- is for searching an order
            if ( m[1] != undefined ) {
                if(m[2].length == 0)
                    return;

                let api = new API();
                this.searchLoading = true;
                switch(m[1].toLowerCase()) {
                    case 'c-': 
                        this.$emit('input', true);

                        try {
                            let res = await api.get(`/customers/${m[2]}`);
                            this.searchLoading = false;

                            if( res.data.status !== true && res.data.error ) {
                                this.mxShowMessage( res.data.message, 'error' );
                            }

                            if( !res.data.status && !res.data.error ) {
                                this.$emit('find', {});
                                this.searchLoading = false;
                                this.find = '';
                                return;
                            }

                            if( res.data.data.length == 1  || !this.showMultipleCustomers ) {
                                this.$emit('find', res.data.data[0]);
                                this.searchLoading = false;
                                this.find = '';
                            }
                            else if( res.data.data.length > 0 && this.showMultipleCustomers ) {
                                // open dialog with customers options
                                this.customers = res.data.data;
                                this.searchLoading = false;
                                this.customerSelectionDialog = true;
                            }

                            this.searchLoading = false;
                            this.$emit('input', false);
                        }
                        catch(error) {
                            this.mxShowMessage(error, 'error');
                        }

                        break;
                    case 'e-': 
                        this.$emit('input', true);

                        try {
                            let res = await api.get(this.eventForReservationPage ? `/events/reservation/${m[2]}` :  `/events/${m[2]}`);
                            this.searchLoading = false;

                            if( !res.data.status && res.data.error ) {
                                this.mxShowMessage( res.data.message, 'error' );
                            }

                            if( !res.data.status && !res.data.error ) {
                                this.$emit('find', {});
                                this.searchLoading = false;
                                this.find = '';
                                return;
                            }

                            if( res.data.data.length > 0 ) {
                                this.$emit('find', res.data.data[0]);
                                this.searchLoading = false;
                                this.find = '';
                            }

                            this.searchLoading = false;
                            this.$emit('input', false);
                        }
                        catch(error) {
                            this.mxShowMessage(error, 'error');
                        }

                        break;
                    case 'r-': this.mxShowMessage( 'find reservation: ' + m[2], '' ); break;
                    case 'o-': 
                        this.$emit('input', true);

                        try {
                            let res = await api.get(`/orders/${m[2]}`);
                            this.searchLoading = false;

                            if( !res.data.status && res.data.error ) {
                                this.mxShowMessage( 'ERROR: ' + res.data.message, 'error' );
                            }

                            if( !res.data.status && !res.data.error ) {
                                this.$emit('find', {});
                                this.searchLoading = false;
                                this.find = '';
                                return;
                            }

                            if( res.data.data.length > 0 ) {
                                this.$emit('find', res.data.data[0]);
                                this.searchLoading = false;
                                this.find = '';
                            }

                            this.searchLoading = false;
                            this.$emit('input', false);
                        }
                        catch(error) {
                            this.mxShowMessage(error, 'error');
                        }

                        break;
                }
            }
        },
        selectCustomer(customer) {
            this.$emit('find', customer);
            if( customer.selected == undefined )
                Vue.set( customer, 'selected', true );
        },
        closeCustomerSelectionDialog() {
            this.searchLoading = false;
            this.find = '';
            this.customerSelectionDialog = false;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>